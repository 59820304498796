import { Subject, timer } from 'rxjs';
import { TokenManagementService } from './token-management.service';
var IdleTimeoutService = /** @class */ (function () {
    function IdleTimeoutService(tokenSvc) {
        this.tokenSvc = tokenSvc;
        this._count = 0;
        this._timeoutMilliseconds = 21600000;
        this.resetOnTrigger = false;
        this.dateTimerInterval = 1000 * 60 * 5000;
        this.dateTimerTolerance = 1000 * 10000;
        this.timeoutExpired = new Subject();
        this.notify_timeoutExpired = new Subject();
        this.minutesToNotifyFrom = 1;
        if (this.tokenSvc.isCurrentTokenValid()) {
            this.startTimer();
            this.startDateCompare();
        }
    }
    IdleTimeoutService.prototype.setSubscription = function () {
        var _this = this;
        this.setTokenExpiryTimeDifference();
        this._timer = timer(this._timeoutMilliseconds); // final timeout
        this.notify_timer = timer(this._timeoutMilliseconds - (this.minutesToNotifyFrom * 60000)); // timeout to notify user 
        this.timerSubscription = this._timer.subscribe(function (n) {
            _this.timerComplete(n);
        });
        this.timerSubscription = this.notify_timer.subscribe(function (n) {
            _this.notifyUser(n);
        });
    };
    IdleTimeoutService.prototype.startDateCompare = function () {
        var _this = this;
        this.lastTime = (new Date()).getTime();
        this.dateTimer = timer(this.dateTimerInterval); // compare every five minutes
        this.dateTimerSubscription = this.dateTimer.subscribe(function (n) {
            var currentTime = (new Date()).getTime();
            if (currentTime > (_this.lastTime + _this.dateTimerInterval + _this.dateTimerTolerance)) {
                console.log("Looks like the machine just woke up.. ");
            }
            else {
                console.log("Machine did not sleep.. ");
            }
            _this.dateTimerSubscription.unsubscribe();
            _this.startDateCompare();
        });
    };
    IdleTimeoutService.prototype.setTokenExpiryTimeDifference = function () {
        var today = new Date();
        this._timeoutMilliseconds = this.getMsDifference(this.tokenSvc.getTokenExpiryDateTime(), today);
    };
    IdleTimeoutService.prototype.getMsDifference = function (from, to) {
        return Math.abs(from.valueOf() - to.valueOf());
    };
    IdleTimeoutService.prototype.startTimer = function () {
        if (this.timerSubscription) {
            this.stopTimer();
        }
        this.setSubscription();
    };
    IdleTimeoutService.prototype.stopTimer = function () {
        this.timerSubscription.unsubscribe();
    };
    IdleTimeoutService.prototype.resetTimer = function () {
        this.startTimer();
    };
    IdleTimeoutService.prototype.timerComplete = function (n) {
        this.timeoutExpired.next(++this._count);
        if (this.resetOnTrigger) {
            this.startTimer();
        }
    };
    IdleTimeoutService.prototype.notifyUser = function (n) {
        this.notify_timeoutExpired.next(++this._count);
        if (this.resetOnTrigger) {
            this.startTimer();
        }
    };
    return IdleTimeoutService;
}());
export { IdleTimeoutService };
