var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { AbpMultiTenancyService } from '@abp/multi-tenancy/abp-multi-tenancy.service';
import { AbpSessionService } from '@abp/session/abp-session.service';
import { AfterViewInit, Injector, OnInit } from '@angular/core';
import { ImpersonationService } from '@app/admin/users/impersonation.service';
import { AppAuthService } from '@app/shared/common/auth/app-auth.service';
import { LinkedAccountService } from '@app/shared/layout/linked-account.service';
import { UserNotificationHelper } from '@app/shared/layout/notifications/UserNotificationHelper';
import { NotificationSettingsModalComponent } from '@app/shared/layout/notifications/notification-settings-modal.component';
import { AppConsts } from '@shared/AppConsts';
import { EditionPaymentType, SubscriptionStartType } from '@shared/AppEnums';
import { AppComponentBase } from '@shared/common/app-component-base';
import { AppSessionService } from '@shared/common/session/app-session.service';
import { CartServiceProxy, ChangeUserLanguageDto, ProfileServiceProxy, RateOfExchangeServiceProxy, SessionServiceProxy, TenantLoginInfoDto, UserLinkServiceProxy, UserServiceProxy, } from '@shared/service-proxies/service-proxies';
import * as _ from 'lodash';
import * as moment from 'moment';
import { LinkedAccountsModalComponent } from './linked-accounts-modal.component';
import { LoginAttemptsModalComponent } from './login-attempts-modal.component';
import { ChangePasswordModalComponent } from './profile/change-password-modal.component';
import { ChangeProfilePictureModalComponent } from './profile/change-profile-picture-modal.component';
import { MySettingsModalComponent } from './profile/my-settings-modal.component';
import { CartDataService } from '@app/shared/common/services/cart.data.service';
import { Router } from '@angular/router';
import { HeaderDataService } from '../common/services/header.data.service';
import { UserTwoFactorAlertComponent } from "@app/shared/common/user-two-factor-alert/user-two-factor-alert.component";
import { UserAccountService } from "@app/shared/common/services/user-account.service";
import { AppMenuItem, MenuItemUsers } from "@app/shared/layout/nav/app-menu-item";
var HeaderComponent = /** @class */ (function (_super) {
    __extends(HeaderComponent, _super);
    function HeaderComponent(injector, _abpSessionService, _abpMultiTenancyService, _profileServiceProxy, _userLinkServiceProxy, _userServiceProxy, _authService, _impersonationService, _linkedAccountService, _userNotificationHelper, _sessionService, _appSessionService, _cartService, _roeService, cartDataService, headerDataService, _router, _userAccountService) {
        var _this = _super.call(this, injector) || this;
        _this._abpSessionService = _abpSessionService;
        _this._abpMultiTenancyService = _abpMultiTenancyService;
        _this._profileServiceProxy = _profileServiceProxy;
        _this._userLinkServiceProxy = _userLinkServiceProxy;
        _this._userServiceProxy = _userServiceProxy;
        _this._authService = _authService;
        _this._impersonationService = _impersonationService;
        _this._linkedAccountService = _linkedAccountService;
        _this._userNotificationHelper = _userNotificationHelper;
        _this._sessionService = _sessionService;
        _this._appSessionService = _appSessionService;
        _this._cartService = _cartService;
        _this._roeService = _roeService;
        _this.cartDataService = cartDataService;
        _this.headerDataService = headerDataService;
        _this._router = _router;
        _this._userAccountService = _userAccountService;
        _this.basic = false;
        _this.isImpersonatedLogin = false;
        _this.isMultiTenancyEnabled = false;
        _this.shownLoginNameTitle = '';
        _this.shownLoginName = '';
        _this.tenancyName = '';
        _this.displayName = '';
        _this.userName = '';
        _this.name = '';
        _this.rateOfExchange = '';
        _this.profilePicture = AppConsts.appBaseUrl + '/assets/common/images/default-profile-picture.png';
        // defaultLogo = AppConsts.appBaseUrl + '/assets/common/images/app-logo-on-' + this.ui.getAsideSkin() + '.png';
        _this.defaultLogo = AppConsts.appBaseUrl + '/assets/common/images/Axiz_logo-transparent.png?version=1';
        _this.unreadChatMessageCount = 0;
        _this.cartItemCount = 0;
        _this.remoteServiceBaseUrl = AppConsts.remoteServiceBaseUrl;
        _this.chatConnected = false;
        _this.tenant = new TenantLoginInfoDto();
        _this.subscriptionStartType = SubscriptionStartType;
        _this.editionPaymentType = EditionPaymentType;
        _this.subscription = null;
        _this.fixedROESubscription = null;
        _this.isLoggedIn = false;
        _this.platformAdminButtonList = [];
        _this.customDomain = AppConsts.customDomain;
        _this.showROE = _this.feature.isEnabled("App.WhiteLabeling.ShowExchangeRate");
        _this.showPhoneNumber = _this.feature.isEnabled("App.UserManagement.ShowHidePhoneNumber");
        _this.hasAxizContentFeature = !_this.feature.isEnabled("App.WhiteLabeling") || !_this.customDomain;
        return _this;
    }
    Object.defineProperty(HeaderComponent.prototype, "multiTenancySideIsTenant", {
        get: function () {
            return this._abpSessionService.tenantId > 0;
        },
        enumerable: true,
        configurable: true
    });
    HeaderComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.isLoggedIn = this._abpSessionService.userId != null && this._abpSessionService.userId > 0;
        this.isMultiTenancyEnabled = this._abpMultiTenancyService.isEnabled;
        this._userNotificationHelper.settingsModal = this.notificationSettingsModal;
        this.languages = _.filter(this.localization.languages, function (l) { return l.isDisabled === false; });
        this.currentLanguage = this.localization.currentLanguage;
        this.isImpersonatedLogin = this._abpSessionService.impersonatorUserId > 0;
        this.shownLoginNameTitle = this.isImpersonatedLogin ? this.l('YouCanBackToYourAccount') : '';
        var effectiveDate = moment();
        if (this.showROE || !this.isLoggedIn) {
            this._roeService.getRateOfExchange('USD', 'ZAR', effectiveDate, AppConsts.selecteMarket).subscribe(function (result) {
                //this.rateOfExchange = 'R ' + result.toFixed(2);
                _this.headerDataService.updateCurrentRoe(result);
            });
            this.fixedROESubscription = this.headerDataService.currentRoe.subscribe(function (rate) {
                if (rate) {
                    _this.rateOfExchange = 'R ' + rate.toFixed(2);
                }
            });
        }
        if (!this.isLoggedIn) {
            return;
        }
        this.getCurrentLoginInformations();
        this.getProfilePicture();
        this.getRecentlyLinkedUsers();
        this.getCartItemCount();
        this.registerToEvents();
        this.subscription = this.cartDataService.currentCart.subscribe(function (cart) {
            if (cart != null && cart.items != null) {
                _this.cartItemCount = _.sumBy(cart.items, function (item) { return item.purchaseDecision.quantity; });
            }
            else {
                _this.cartItemCount = 0;
            }
        });
        this.platformAdminButtonList = this.GetPlatformAdminButtonList();
    };
    HeaderComponent.prototype.ngAfterViewInit = function () {
        this.showTwoFactorAlertModal();
    };
    HeaderComponent.prototype.ngOnDestroy = function () {
        if (this.subscription !== null) {
            this.subscription.unsubscribe();
        }
        if (this.fixedROESubscription !== null) {
            this.fixedROESubscription.unsubscribe();
        }
    };
    HeaderComponent.prototype.registerToEvents = function () {
        var _this = this;
        abp.event.on('profilePictureChanged', function () {
            _this.getProfilePicture();
        });
        abp.event.on('app.chat.unreadMessageCountChanged', function (messageCount) {
            _this.unreadChatMessageCount = messageCount;
        });
        abp.event.on('app.chat.connected', function () {
            _this.chatConnected = true;
        });
    };
    HeaderComponent.prototype.changeLanguage = function (languageName) {
        var input = new ChangeUserLanguageDto();
        input.languageName = languageName;
        this._profileServiceProxy.changeLanguage(input).subscribe(function () {
            abp.utils.setCookieValue('Abp.Localization.CultureName', languageName, new Date(new Date().getTime() + 5 * 365 * 86400000), //5 year
            abp.appPath);
            window.location.reload();
        });
    };
    HeaderComponent.prototype.getCurrentLoginInformations = function () {
        var _this = this;
        this.shownLoginName = this.appSession.getShownLoginName();
        this.tenancyName = this.appSession.tenancyName;
        this.userName = this.appSession.user.userName;
        this.displayName = this.tenancyName + '\\' + this.userName;
        this.name = this.appSession.user.name;
        this._sessionService.getCurrentLoginInformations()
            .subscribe(function (result) {
            _this.tenant = result.tenant;
        });
    };
    HeaderComponent.prototype.getShownUserName = function (linkedUser) {
        if (!this._abpMultiTenancyService.isEnabled) {
            return linkedUser.username;
        }
        return (linkedUser.tenantId ? linkedUser.tenancyName : '.') + '\\' + linkedUser.username;
    };
    HeaderComponent.prototype.getProfilePicture = function () {
        var _this = this;
        this._profileServiceProxy.getProfilePicture().subscribe(function (result) {
            if (result && result.profilePicture) {
                _this.profilePicture = 'data:image/jpeg;base64,' + result.profilePicture;
            }
        });
    };
    Object.defineProperty(HeaderComponent.prototype, "isTenantSelfRegistrationAllowed", {
        get: function () {
            return this.setting.getBoolean('App.TenantManagement.AllowSelfRegistration');
        },
        enumerable: true,
        configurable: true
    });
    HeaderComponent.prototype.getRecentlyLinkedUsers = function () {
        var _this = this;
        this._userLinkServiceProxy.getRecentlyUsedLinkedUsers().subscribe(function (result) {
            _this.recentlyLinkedUsers = result.items;
        });
    };
    HeaderComponent.prototype.getCartItemCount = function () {
        var _this = this;
        this._cartService.getCartItemCount().subscribe(function (result) {
            _this.cartItemCount = result;
        });
    };
    HeaderComponent.prototype.showLoginAttempts = function () {
        this.loginAttemptsModal.show();
    };
    HeaderComponent.prototype.showLinkedAccounts = function () {
        this.linkedAccountsModal.show();
    };
    HeaderComponent.prototype.changePassword = function () {
        this.changePasswordModal.show();
    };
    HeaderComponent.prototype.changeProfilePicture = function () {
        this.changeProfilePictureModal.show();
    };
    HeaderComponent.prototype.changeMySettings = function () {
        this.mySettingsModal.show();
    };
    HeaderComponent.prototype.logout = function () {
        this._authService.logout();
    };
    HeaderComponent.prototype.onMySettingsModalSaved = function () {
        this.shownLoginName = this.appSession.getShownLoginName();
    };
    HeaderComponent.prototype.backToMyAccount = function () {
        this._impersonationService.backToImpersonator();
    };
    HeaderComponent.prototype.switchToLinkedUser = function (linkedUser) {
        this._linkedAccountService.switchToAccount(linkedUser.id, linkedUser.tenantId);
    };
    Object.defineProperty(HeaderComponent.prototype, "chatEnabled", {
        get: function () {
            return (!this._abpSessionService.tenantId || this.feature.isEnabled('App.ChatFeature'));
        },
        enumerable: true,
        configurable: true
    });
    HeaderComponent.prototype.subscriptionStatusBarVisible = function () {
        return this._appSessionService.tenantId > 0 && (this._appSessionService.tenant.isInTrialPeriod || this.subscriptionIsExpiringSoon());
    };
    HeaderComponent.prototype.subscriptionIsExpiringSoon = function () {
        if (this._appSessionService.tenant.subscriptionEndDateUtc) {
            return moment().utc().add(AppConsts.subscriptionExpireNotifyDayCount, 'days') >= moment(this._appSessionService.tenant.subscriptionEndDateUtc);
        }
        return false;
    };
    HeaderComponent.prototype.getSubscriptionExpiringDayCount = function () {
        if (!this._appSessionService.tenant.subscriptionEndDateUtc) {
            return 0;
        }
        return Math.round(moment(this._appSessionService.tenant.subscriptionEndDateUtc).diff(moment().utc(), 'days', true));
    };
    HeaderComponent.prototype.getTrialSubscriptionNotification = function () {
        return this.l('TrialSubscriptionNotification', '<strong>' + this._appSessionService.tenant.edition.displayName + '</strong>', '<a href=\'/account/buy?editionId=' + this._appSessionService.tenant.edition.id + '&editionPaymentType=' + this.editionPaymentType.BuyNow + '\'>' + this.l('ClickHere') + '</a>');
    };
    HeaderComponent.prototype.getExpireNotification = function (localizationKey) {
        return this.l(localizationKey, this.getSubscriptionExpiringDayCount());
    };
    HeaderComponent.prototype.login = function () {
        this._router.navigate(['/account/login'], { queryParams: { returnUrl: window.location.href } });
    };
    HeaderComponent.prototype.showTwoFactorAlertModal = function () {
        var _this = this;
        this._userAccountService.getTwoFactorStatus()
            .subscribe(function (result) {
            if (result.isTwoFactorSettingEnabled && !result.isUserTwoFactorEnabled) {
                _this.twoFactorAlertModal.show();
            }
        });
    };
    HeaderComponent.prototype.view2FaSettings = function () {
        this.mySettingsModal.showTwoFactor();
    };
    HeaderComponent.prototype.GetPlatformAdminButtonList = function () {
        if (!this.permission.isGranted("Pages.Administration") || !this.isLoggedIn) {
            return [];
        }
        var platformAdminActions = [
            new AppMenuItem({
                name: this.l("AdminMenuRoles"),
                users: [MenuItemUsers.Tenant, MenuItemUsers.Host],
                permissionName: 'Pages.Administration.Roles',
                route: '/app/admin/roles',
                subSection: 'User Management'
            }),
            new AppMenuItem({
                name: this.l("AdminMenuUsers"),
                users: [MenuItemUsers.Tenant, MenuItemUsers.Host],
                permissionName: 'Pages.Administration.Users',
                route: '/app/admin/users',
                subSection: 'User Management'
            }),
            new AppMenuItem({
                name: this.l("AdminMenuDocuments"),
                users: [MenuItemUsers.Tenant, MenuItemUsers.Host],
                permissionName: 'Pages.Administration.Documents',
                route: '/app/admin/documents',
                subSection: 'Profile'
            }),
            new AppMenuItem({
                name: this.l("AdminMenuIntegration"),
                users: [MenuItemUsers.Tenant, MenuItemUsers.Host],
                permissionName: 'Pages.Administration.Integration',
                route: '/app/admin/integration',
                subSection: 'Profile'
            }),
            new AppMenuItem({
                name: this.l("AdminMenuSettings"),
                users: [MenuItemUsers.Host],
                permissionName: 'Pages.Administration.Host.Settings',
                route: '/app/admin/hostSettings',
                subSection: 'Profile'
            }),
            new AppMenuItem({
                name: this.l("AdminMenuOrganizationalUnits"),
                users: [MenuItemUsers.Host],
                permissionName: 'Pages.Administration.OrganizationUnits',
                route: '/app/admin/organization-units',
                subSection: 'Profile'
            }),
            new AppMenuItem({
                name: this.l("AdminMenuSettings"),
                users: [MenuItemUsers.Tenant],
                permissionName: 'Pages.Administration.Tenant.Settings',
                route: '/app/admin/tenantSettings',
                subSection: 'Profile'
            }),
            new AppMenuItem({
                name: this.l("AdminMenuJourney"),
                users: [MenuItemUsers.Tenant, MenuItemUsers.Host],
                permissionName: 'Pages.Administration.JourneyManager',
                route: '/app/admin/journey-management',
                subSection: 'Profile'
            }),
            new AppMenuItem({
                name: this.l("AdminMenuAdobe"),
                users: [MenuItemUsers.Tenant, MenuItemUsers.Host],
                permissionName: 'Pages.Administration.PriceList.Adobe',
                route: '/app/admin/price-list/adobe',
                subSection: 'Pricelists'
            }),
            new AppMenuItem({
                name: this.l("AdminMenuMarketplace"),
                users: [MenuItemUsers.Tenant, MenuItemUsers.Host],
                permissionName: 'Pages.Administration.PriceList.Adobe',
                route: '/app/admin/price-list/adobe',
                subSection: 'Pricelists'
            }), new AppMenuItem({
                name: 'Marketplace',
                users: [MenuItemUsers.Tenant, MenuItemUsers.Host],
                permissionName: 'Pages.Administration.PriceList.Upload',
                route: '/app/admin/price-list/upload',
                subSection: 'Pricelists'
            }),
            new AppMenuItem({
                name: this.l("AdminMenuMicrosoft"),
                users: [MenuItemUsers.Tenant, MenuItemUsers.Host],
                permissionName: 'Pages.Administration.PriceList.Microsoft',
                route: '/app/admin/price-list/microsoft',
                subSection: 'Pricelists'
            }),
            new AppMenuItem({
                name: this.l("AdminMenuCisco"),
                users: [MenuItemUsers.Tenant, MenuItemUsers.Host],
                permissionName: 'Pages.Administration.Vendors.Cisco',
                route: '/app/admin/vendor/cisco',
                subSection: 'Vendors'
            }),
            new AppMenuItem({
                name: this.l("AdminMenuMicrosoft"),
                users: [MenuItemUsers.Tenant, MenuItemUsers.Host],
                permissionName: 'Pages.Administration.Vendors.Microsoft',
                route: '/app/admin/vendor/microsoft',
                subSection: 'Vendors'
            }),
            new AppMenuItem({
                name: this.l("AdminMenuMarketplace"),
                users: [MenuItemUsers.Host],
                permissionName: 'Pages.Administration.Vendors.MarketPlace',
                route: '/app/admin/vendor/market-place',
                subSection: 'Vendors'
            }),
            new AppMenuItem({
                name: this.l("AdminMenuAdvancedPricingSystem"),
                users: [MenuItemUsers.Host],
                permissionName: 'Pages.Administration.Host.PricingMaintenance',
                route: '/app/admin/pricing-maintenance',
                subSection: 'Pricing System'
            }),
        ];
        var user;
        if (this._abpSessionService.userId != null && this._abpSessionService.userId > 0 && this._appSessionService.tenantId != null && this._appSessionService.tenantId > 0) {
            user = MenuItemUsers.Tenant;
        }
        else if (this._abpSessionService.userId != null && this._abpSessionService.userId > 0 && this._appSessionService.tenantId == null) {
            user = MenuItemUsers.Host;
        }
        else {
            user = MenuItemUsers.Guest;
        }
        var buttonActions = new Map();
        for (var _i = 0, platformAdminActions_1 = platformAdminActions; _i < platformAdminActions_1.length; _i++) {
            var action = platformAdminActions_1[_i];
            if (this.permission.isGranted(action.permissionName) && action.users.indexOf(user) > -1) {
                var section = buttonActions.get(action.subSection);
                if (!section) {
                    buttonActions.set(action.subSection, []);
                    section = buttonActions.get(action.subSection);
                }
                section.push({
                    name: action.name,
                    link: action.route
                });
            }
        }
        var buttonGroups = [];
        buttonActions.forEach(function (val, key) {
            buttonGroups.push({
                title: key,
                actions: val,
            });
        });
        return buttonGroups;
    };
    HeaderComponent.prototype.navigateToAction = function (action) {
        this._router.navigate([action.link]);
    };
    return HeaderComponent;
}(AppComponentBase));
export { HeaderComponent };
