import {AbpMultiTenancyService} from '@abp/multi-tenancy/abp-multi-tenancy.service';
import {AbpSessionService} from '@abp/session/abp-session.service';
import {AfterViewInit, Component, Injector, Input, OnInit, ViewChild, ViewEncapsulation} from '@angular/core';
import {ImpersonationService} from '@app/admin/users/impersonation.service';
import {AppAuthService} from '@app/shared/common/auth/app-auth.service';
import {LinkedAccountService} from '@app/shared/layout/linked-account.service';
import {UserNotificationHelper} from '@app/shared/layout/notifications/UserNotificationHelper';
import {
    NotificationSettingsModalComponent
} from '@app/shared/layout/notifications/notification-settings-modal.component';
import {AppConsts} from '@shared/AppConsts';
import {EditionPaymentType, SubscriptionStartType} from '@shared/AppEnums';
import {AppComponentBase} from '@shared/common/app-component-base';
import {AppSessionService} from '@shared/common/session/app-session.service';
import {
    CartServiceProxy,
    ChangeUserLanguageDto,
    GetCurrentLoginInformationsOutput,
    LinkedUserDto,
    ProfileServiceProxy,
    RateOfExchangeServiceProxy,
    SessionServiceProxy,
    TenantLoginInfoDto,
    UserLinkServiceProxy,
    UserServiceProxy,
} from '@shared/service-proxies/service-proxies';
import * as _ from 'lodash';
import * as moment from 'moment';
import {LinkedAccountsModalComponent} from './linked-accounts-modal.component';
import {LoginAttemptsModalComponent} from './login-attempts-modal.component';
import {ChangePasswordModalComponent} from './profile/change-password-modal.component';
import {ChangeProfilePictureModalComponent} from './profile/change-profile-picture-modal.component';
import {MySettingsModalComponent} from './profile/my-settings-modal.component';
import {CartDataService} from '@app/shared/common/services/cart.data.service';
import {Subscription} from 'rxjs';
import {Router} from '@angular/router';
import {HeaderDataService} from '../common/services/header.data.service';
import {UserTwoFactorAlertComponent} from "@app/shared/common/user-two-factor-alert/user-two-factor-alert.component";
import {UserAccountService} from "@app/shared/common/services/user-account.service";
import {AppMenuItem, MenuItemUsers} from "@app/shared/layout/nav/app-menu-item";

interface PlatformAdminButtonActionGroup {
    title: string;
    actions: PlatformAdminButtonAction[];
}

interface PlatformAdminButtonAction {
    name: string;
    link: string;
}

@Component({
    templateUrl: './header.component.html',
    selector: 'header-bar',
    styleUrls: ['./header.component.less'],
    encapsulation: ViewEncapsulation.None
})
export class HeaderComponent extends AppComponentBase implements OnInit, AfterViewInit {
    @ViewChild('notificationSettingsModal') notificationSettingsModal: NotificationSettingsModalComponent;
    @ViewChild('loginAttemptsModal') loginAttemptsModal: LoginAttemptsModalComponent;
    @ViewChild('linkedAccountsModal') linkedAccountsModal: LinkedAccountsModalComponent;
    @ViewChild('changePasswordModal') changePasswordModal: ChangePasswordModalComponent;
    @ViewChild('changeProfilePictureModal') changeProfilePictureModal: ChangeProfilePictureModalComponent;
    @ViewChild('mySettingsModal') mySettingsModal: MySettingsModalComponent;
    @ViewChild('twoFactorAlertModal') twoFactorAlertModal: UserTwoFactorAlertComponent;

    @Input() basic: boolean = false;

    languages: abp.localization.ILanguageInfo[];
    currentLanguage: abp.localization.ILanguageInfo;
    isImpersonatedLogin = false;
    isMultiTenancyEnabled = false;
    shownLoginNameTitle = '';
    shownLoginName = '';
    tenancyName = '';
    displayName = '';
    userName = '';
    name = '';
    rateOfExchange = '';
    profilePicture = AppConsts.appBaseUrl + '/assets/common/images/default-profile-picture.png';
    // defaultLogo = AppConsts.appBaseUrl + '/assets/common/images/app-logo-on-' + this.ui.getAsideSkin() + '.png';
    defaultLogo = AppConsts.appBaseUrl + '/assets/common/images/Axiz_logo-transparent.png?version=1';
    recentlyLinkedUsers: LinkedUserDto[];
    unreadChatMessageCount = 0;
    cartItemCount = 0;
    remoteServiceBaseUrl: string = AppConsts.remoteServiceBaseUrl;
    chatConnected = false;
    tenant = new TenantLoginInfoDto();
    subscriptionStartType = SubscriptionStartType;
    editionPaymentType = EditionPaymentType;
    private subscription: Subscription = null;
    private fixedROESubscription: Subscription = null;
    isLoggedIn = false;
    platformAdminButtonList: PlatformAdminButtonActionGroup[] = [];

    customDomain = AppConsts.customDomain;
    showROE = this.feature.isEnabled("App.WhiteLabeling.ShowExchangeRate");
    showPhoneNumber = this.feature.isEnabled("App.UserManagement.ShowHidePhoneNumber");
    hasAxizContentFeature = !this.feature.isEnabled("App.WhiteLabeling") || !this.customDomain;

    constructor(
        injector: Injector,
        private _abpSessionService: AbpSessionService,
        private _abpMultiTenancyService: AbpMultiTenancyService,
        private _profileServiceProxy: ProfileServiceProxy,
        private _userLinkServiceProxy: UserLinkServiceProxy,
        private _userServiceProxy: UserServiceProxy,
        private _authService: AppAuthService,
        private _impersonationService: ImpersonationService,
        private _linkedAccountService: LinkedAccountService,
        private _userNotificationHelper: UserNotificationHelper,
        private _sessionService: SessionServiceProxy,
        private _appSessionService: AppSessionService,
        private _cartService: CartServiceProxy,
        private _roeService: RateOfExchangeServiceProxy,
        private readonly cartDataService: CartDataService,
        private readonly headerDataService: HeaderDataService,
        private _router: Router,
        private _userAccountService: UserAccountService
    ) {
        super(injector);
    }

    get multiTenancySideIsTenant(): boolean {
        return this._abpSessionService.tenantId > 0;
    }

    ngOnInit() {

        this.isLoggedIn = this._abpSessionService.userId != null && this._abpSessionService.userId > 0;


        this.isMultiTenancyEnabled = this._abpMultiTenancyService.isEnabled;
        this._userNotificationHelper.settingsModal = this.notificationSettingsModal;
        this.languages = _.filter(this.localization.languages, l => (<any>l).isDisabled === false);
        this.currentLanguage = this.localization.currentLanguage;
        this.isImpersonatedLogin = this._abpSessionService.impersonatorUserId > 0;
        this.shownLoginNameTitle = this.isImpersonatedLogin ? this.l('YouCanBackToYourAccount') : '';

        let effectiveDate = moment()

        if (this.showROE || !this.isLoggedIn) {
            this._roeService.getRateOfExchange('USD', 'ZAR', effectiveDate, AppConsts.selecteMarket).subscribe(result => {
                //this.rateOfExchange = 'R ' + result.toFixed(2);
                this.headerDataService.updateCurrentRoe(result);
            });

            this.fixedROESubscription = this.headerDataService.currentRoe.subscribe(rate => {
                if (rate) {
                    this.rateOfExchange = 'R ' + rate.toFixed(2);
                }
            });
        }

        if (!this.isLoggedIn) {
            return;
        }

        this.getCurrentLoginInformations();
        this.getProfilePicture();
        this.getRecentlyLinkedUsers();
        this.getCartItemCount();
        this.registerToEvents();

        this.subscription = this.cartDataService.currentCart.subscribe(cart => {
            if (cart != null && cart.items != null) {
                this.cartItemCount = _.sumBy(cart.items, item => item.purchaseDecision.quantity);
            } else {
                this.cartItemCount = 0;
            }
        });

        this.platformAdminButtonList = this.GetPlatformAdminButtonList();
    }

    ngAfterViewInit() {
        this.showTwoFactorAlertModal();
    }

    ngOnDestroy() {
        if (this.subscription !== null) {
            this.subscription.unsubscribe();
        }

        if (this.fixedROESubscription !== null) {
            this.fixedROESubscription.unsubscribe();
        }
    }

    registerToEvents() {
        abp.event.on('profilePictureChanged', () => {
            this.getProfilePicture();
        });

        abp.event.on('app.chat.unreadMessageCountChanged', messageCount => {
            this.unreadChatMessageCount = messageCount;
        });

        abp.event.on('app.chat.connected', () => {
            this.chatConnected = true;
        });
    }

    changeLanguage(languageName: string): void {
        const input = new ChangeUserLanguageDto();
        input.languageName = languageName;

        this._profileServiceProxy.changeLanguage(input).subscribe(() => {
            abp.utils.setCookieValue(
                'Abp.Localization.CultureName',
                languageName,
                new Date(new Date().getTime() + 5 * 365 * 86400000), //5 year
                abp.appPath
            );

            window.location.reload();
        });
    }

    getCurrentLoginInformations(): void {
        this.shownLoginName = this.appSession.getShownLoginName();
        this.tenancyName = this.appSession.tenancyName;
        this.userName = this.appSession.user.userName;
        this.displayName = this.tenancyName + '\\' + this.userName;
        this.name = this.appSession.user.name;

        this._sessionService.getCurrentLoginInformations()
            .subscribe((result: GetCurrentLoginInformationsOutput) => {
                this.tenant = result.tenant;
            });
    }

    getShownUserName(linkedUser: LinkedUserDto): string {
        if (!this._abpMultiTenancyService.isEnabled) {
            return linkedUser.username;
        }

        return (linkedUser.tenantId ? linkedUser.tenancyName : '.') + '\\' + linkedUser.username;
    }

    getProfilePicture(): void {
        this._profileServiceProxy.getProfilePicture().subscribe(result => {
            if (result && result.profilePicture) {
                this.profilePicture = 'data:image/jpeg;base64,' + result.profilePicture;
            }
        });
    }

    get isTenantSelfRegistrationAllowed(): boolean {
        return this.setting.getBoolean('App.TenantManagement.AllowSelfRegistration');
    }

    getRecentlyLinkedUsers(): void {
        this._userLinkServiceProxy.getRecentlyUsedLinkedUsers().subscribe(result => {
            this.recentlyLinkedUsers = result.items;
        });
    }

    getCartItemCount(): void {
        this._cartService.getCartItemCount().subscribe(result => {
            this.cartItemCount = result;
        });
    }

    showLoginAttempts(): void {
        this.loginAttemptsModal.show();
    }

    showLinkedAccounts(): void {
        this.linkedAccountsModal.show();
    }

    changePassword(): void {
        this.changePasswordModal.show();
    }

    changeProfilePicture(): void {
        this.changeProfilePictureModal.show();
    }

    changeMySettings(): void {
        this.mySettingsModal.show();
    }

    logout(): void {
        this._authService.logout();
    }

    onMySettingsModalSaved(): void {
        this.shownLoginName = this.appSession.getShownLoginName();
    }

    backToMyAccount(): void {
        this._impersonationService.backToImpersonator();
    }

    switchToLinkedUser(linkedUser: LinkedUserDto): void {
        this._linkedAccountService.switchToAccount(linkedUser.id, linkedUser.tenantId);
    }

    get chatEnabled(): boolean {
        return (!this._abpSessionService.tenantId || this.feature.isEnabled('App.ChatFeature'));
    }

    subscriptionStatusBarVisible(): boolean {
        return this._appSessionService.tenantId > 0 && (this._appSessionService.tenant.isInTrialPeriod || this.subscriptionIsExpiringSoon());
    }

    subscriptionIsExpiringSoon(): boolean {
        if (this._appSessionService.tenant.subscriptionEndDateUtc) {
            return moment().utc().add(AppConsts.subscriptionExpireNotifyDayCount, 'days') >= moment(this._appSessionService.tenant.subscriptionEndDateUtc);
        }

        return false;
    }

    getSubscriptionExpiringDayCount(): number {
        if (!this._appSessionService.tenant.subscriptionEndDateUtc) {
            return 0;
        }

        return Math.round(moment(this._appSessionService.tenant.subscriptionEndDateUtc).diff(moment().utc(), 'days', true));
    }

    getTrialSubscriptionNotification(): string {
        return this.l('TrialSubscriptionNotification',
            '<strong>' + this._appSessionService.tenant.edition.displayName + '</strong>',
            '<a href=\'/account/buy?editionId=' + this._appSessionService.tenant.edition.id + '&editionPaymentType=' + this.editionPaymentType.BuyNow + '\'>' + this.l('ClickHere') + '</a>');
    }

    getExpireNotification(localizationKey: string): string {
        return this.l(localizationKey, this.getSubscriptionExpiringDayCount());
    }

    login() {
        this._router.navigate(['/account/login'], {queryParams: {returnUrl: window.location.href}});
    }

    showTwoFactorAlertModal() {
        this._userAccountService.getTwoFactorStatus()
            .subscribe((result) => {
                if (result.isTwoFactorSettingEnabled && !result.isUserTwoFactorEnabled) {
                    this.twoFactorAlertModal.show();
                }
            });
    }

    view2FaSettings() {
        this.mySettingsModal.showTwoFactor();
    }

    public GetPlatformAdminButtonList() {
        if (!this.permission.isGranted("Pages.Administration") || !this.isLoggedIn) {
            return [];
        }

        const platformAdminActions =
            [
                new AppMenuItem({
                    name: this.l("AdminMenuRoles"),
                    users: [MenuItemUsers.Tenant, MenuItemUsers.Host],
                    permissionName: 'Pages.Administration.Roles',
                    route: '/app/admin/roles',
                    subSection: 'User Management'
                }),
                new AppMenuItem({
                    name: this.l("AdminMenuUsers"),
                    users: [MenuItemUsers.Tenant, MenuItemUsers.Host],
                    permissionName: 'Pages.Administration.Users',
                    route: '/app/admin/users',
                    subSection: 'User Management'
                }),
                new AppMenuItem({
                    name:  this.l("AdminMenuDocuments"),
                    users: [MenuItemUsers.Tenant, MenuItemUsers.Host],
                    permissionName: 'Pages.Administration.Documents',
                    route: '/app/admin/documents',
                    subSection: 'Profile'
                }),
                new AppMenuItem({
                    name: this.l("AdminMenuIntegration"),
                    users: [MenuItemUsers.Tenant, MenuItemUsers.Host],
                    permissionName: 'Pages.Administration.Integration',
                    route: '/app/admin/integration',
                    subSection: 'Profile'
                }),
                new AppMenuItem({
                    name: this.l("AdminMenuSettings"),
                    users: [MenuItemUsers.Host],
                    permissionName: 'Pages.Administration.Host.Settings',
                    route: '/app/admin/hostSettings',
                    subSection: 'Profile'
                }),
                new AppMenuItem({
                    name: this.l("AdminMenuOrganizationalUnits"),
                    users: [MenuItemUsers.Host],
                    permissionName: 'Pages.Administration.OrganizationUnits',
                    route: '/app/admin/organization-units',
                    subSection: 'Profile'
                }),
                new AppMenuItem({
                    name: this.l("AdminMenuSettings"),
                    users: [MenuItemUsers.Tenant],
                    permissionName: 'Pages.Administration.Tenant.Settings',
                    route: '/app/admin/tenantSettings',
                    subSection: 'Profile'
                }),
                new AppMenuItem({
                    name: this.l("AdminMenuJourney"),
                    users: [MenuItemUsers.Tenant, MenuItemUsers.Host],
                    permissionName: 'Pages.Administration.JourneyManager',
                    route: '/app/admin/journey-management',
                    subSection: 'Profile'
                }),
                new AppMenuItem({
                    name: this.l("AdminMenuAdobe"),
                    users: [MenuItemUsers.Tenant, MenuItemUsers.Host],
                    permissionName: 'Pages.Administration.PriceList.Adobe',
                    route: '/app/admin/price-list/adobe',
                    subSection: 'Pricelists'
                }),
                new AppMenuItem({
                    name: this.l("AdminMenuMarketplace"),
                    users: [MenuItemUsers.Tenant, MenuItemUsers.Host],
                    permissionName: 'Pages.Administration.PriceList.Adobe',
                    route: '/app/admin/price-list/adobe',
                    subSection: 'Pricelists'
                }),new AppMenuItem({
                    name: 'Marketplace',
                    users: [MenuItemUsers.Tenant, MenuItemUsers.Host],
                    permissionName: 'Pages.Administration.PriceList.Upload',
                    route: '/app/admin/price-list/upload',
                    subSection: 'Pricelists'
                }),
                new AppMenuItem({
                    name: this.l("AdminMenuMicrosoft"),
                    users: [MenuItemUsers.Tenant, MenuItemUsers.Host],
                    permissionName: 'Pages.Administration.PriceList.Microsoft',
                    route: '/app/admin/price-list/microsoft',
                    subSection: 'Pricelists'
                }),
                new AppMenuItem({
                    name: this.l("AdminMenuCisco"),
                    users: [MenuItemUsers.Tenant, MenuItemUsers.Host],
                    permissionName: 'Pages.Administration.Vendors.Cisco',
                    route: '/app/admin/vendor/cisco',
                    subSection: 'Vendors'
                }),
                new AppMenuItem({
                    name: this.l("AdminMenuMicrosoft"),
                    users: [MenuItemUsers.Tenant, MenuItemUsers.Host],
                    permissionName: 'Pages.Administration.Vendors.Microsoft',
                    route: '/app/admin/vendor/microsoft',
                    subSection: 'Vendors'
                }),
                new AppMenuItem({
                    name: this.l("AdminMenuMarketplace"),
                    users: [MenuItemUsers.Host],
                    permissionName: 'Pages.Administration.Vendors.MarketPlace',
                    route: '/app/admin/vendor/market-place',
                    subSection: 'Vendors'
                }),
                new AppMenuItem({
                    name:  this.l("AdminMenuAdvancedPricingSystem"),
                    users: [MenuItemUsers.Host],
                    permissionName: 'Pages.Administration.Host.PricingMaintenance',
                    route: '/app/admin/pricing-maintenance',
                    subSection: 'Pricing System'
                }),
            ]

        let user: MenuItemUsers;
        if (this._abpSessionService.userId != null && this._abpSessionService.userId > 0 && this._appSessionService.tenantId != null && this._appSessionService.tenantId > 0) {
            user = MenuItemUsers.Tenant;
        } else if (this._abpSessionService.userId != null && this._abpSessionService.userId > 0 && this._appSessionService.tenantId == null) {
            user = MenuItemUsers.Host;
        } else {
            user = MenuItemUsers.Guest;
        }

        let buttonActions: Map<string, PlatformAdminButtonAction[]> = new Map<string, PlatformAdminButtonAction[]>();
        for (let action of platformAdminActions) {
            if (this.permission.isGranted(action.permissionName) && action.users.indexOf(user) > -1) {

                let section = buttonActions.get(action.subSection)
                if (!section) {
                    buttonActions.set(action.subSection, []);
                    section = buttonActions.get(action.subSection);
                }
                section.push({
                    name: action.name,
                    link: action.route
                });
            }
        }

        let buttonGroups: PlatformAdminButtonActionGroup[] = [];
        buttonActions.forEach((val, key) => {
            buttonGroups.push({
                title: key,
                actions: val,
            })
        })

        return buttonGroups;
    }

    navigateToAction(action: PlatformAdminButtonAction) {
        this._router.navigate([action.link])
    }
}
