import { Injectable } from '@angular/core';
import { Observable, Subject, Subscription, timer } from 'rxjs';
import { TokenManagementService } from './token-management.service';

@Injectable()
export class IdleTimeoutService {
  private _count: number = 0;
  private _timeoutMilliseconds: number = 21600000;
  private timerSubscription: Subscription;
  private _timer: Observable<number>;
  private notify_timer: Observable<number>;
  private resetOnTrigger: boolean = false;
  private lastTime: number;
  private dateTimer: Observable<number>;
  private dateTimerSubscription: Subscription;
  private dateTimerInterval: number = 1000 * 60 * 5000;
  private dateTimerTolerance: number = 1000 * 10000;
  public timeoutExpired: Subject<number> = new Subject<number>();
  public notify_timeoutExpired: Subject<number> = new Subject<number>();

  public minutesToNotifyFrom: number = 1;

  constructor(public tokenSvc: TokenManagementService) {
    if (this.tokenSvc.isCurrentTokenValid())
    {
      this.startTimer();
      this.startDateCompare();
    }
  }

  private setSubscription() {
    this.setTokenExpiryTimeDifference();
    this._timer = timer(this._timeoutMilliseconds); // final timeout
    this.notify_timer = timer(this._timeoutMilliseconds - (this.minutesToNotifyFrom * 60000)) // timeout to notify user 
    this.timerSubscription = this._timer.subscribe(n => {
      this.timerComplete(n);
    });
    this.timerSubscription = this.notify_timer.subscribe(n => {
      this.notifyUser(n);
    });
  }

  private startDateCompare() {
    this.lastTime = (new Date()).getTime();
    this.dateTimer = timer(this.dateTimerInterval); // compare every five minutes
    this.dateTimerSubscription = this.dateTimer.subscribe(n => {
      let currentTime: number = (new Date()).getTime();
      if (currentTime > (this.lastTime + this.dateTimerInterval + this.dateTimerTolerance)) { // look for 10 sec diff
        console.log("Looks like the machine just woke up.. ");
      }
      else {
        console.log("Machine did not sleep.. ");
      }
      this.dateTimerSubscription.unsubscribe();
      this.startDateCompare();
    });
  }

  private setTokenExpiryTimeDifference() {
    var today = new Date();
    this._timeoutMilliseconds = this.getMsDifference(this.tokenSvc.getTokenExpiryDateTime(), today)
  }

  private getMsDifference(from: Date, to: Date): number {
    return Math.abs(from.valueOf() - to.valueOf());
  }

  public startTimer() {
    if (this.timerSubscription) {
      this.stopTimer();
    }

    this.setSubscription();
  }

  public stopTimer() {
    this.timerSubscription.unsubscribe();
  }

  public resetTimer() {
    this.startTimer();
  }

  private timerComplete(n: number) {
    this.timeoutExpired.next(++this._count);

    if (this.resetOnTrigger) {
      this.startTimer();
    }
  }

  private notifyUser(n: number) {
    this.notify_timeoutExpired.next(++this._count);

    if (this.resetOnTrigger) {
      this.startTimer();
    }
  }
}
