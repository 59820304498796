﻿import { Injectable } from '@angular/core';
import { AvaCartDto } from '@shared/service-proxies/service-proxies';
import {BehaviorSubject, Subscription} from 'rxjs';

@Injectable(({
    providedIn: 'root'
}))
export class CartDataService {
    private cartSource = new BehaviorSubject<AvaCartDto>(null);
    currentCart = this.cartSource.asObservable();

    updateCurrentCart(cart: AvaCartDto) { 
        this.cartSource.next(cart);
    }
    
    private cartRefresh = new BehaviorSubject<AvaCartDto>(null);
    currentCartRefresh = this.cartRefresh.asObservable();

    private cartSubscriptions: Subscription[] = [];

    subscribeToCartRefresh(observer: (cart: AvaCartDto) => void) {
        this.clearCartSubscriptions() // Prevents lingering subscriptions 
        const subscription = this.currentCartRefresh.subscribe(observer);
        this.cartSubscriptions.push(subscription);
    }

    // Method to clear all Cart Subscriptions
    clearCartSubscriptions() {
        this.cartSubscriptions.forEach(sub => sub.unsubscribe());
        this.cartSubscriptions = [];
    }

    refreshCurrentCart(cart: AvaCartDto) {
        this.cartRefresh.next(cart);
    }
}